<script setup lang="ts">
import { computed } from "vue";
import { getRange, transform } from "./utils.js";

import {
  GrPaginationPrev,
  GrPaginationNext,
  GrPaginationEllipsis,
  GrPaginationPage,
} from "@/components/GrComponents/pagination";

const props = defineProps({
  page: {
    type: Number,
    required: true,
  },
  pageCount: {
    type: Number,
    required: true,
  },
  siblingCount: {
    type: Number,
    required: true,
  },
});

const range = computed(() => {
  return transform(
    getRange(props.page, props.pageCount, props.siblingCount, true)
  );
});

const nextIsDisabled = computed(() => props.page === props.pageCount);
const prevIsDisabled = computed(() => props.page === 1);

const emit = defineEmits(["onChangePage"]);

function nextPage() {
  if (nextIsDisabled.value) return;
  changePage(props.page + 1);
}

function prevPage() {
  if (prevIsDisabled.value) return;
  changePage(props.page - 1);
}

function changePage(value) {
  emit("onChangePage", value);
}
</script>
<template>
  <ol class="pagination-list">
    <GrPaginationPrev
      class="pagination-list-item"
      :disabled="prevIsDisabled"
      @click="prevPage"
    />

    <div v-for="(item, index) of range" :key="index">
      <div v-if="item.type === 'ellipsis'">
        <GrPaginationEllipsis />
      </div>

      <div v-else>
        <GrPaginationPage
          class="pagination-list-item"
          :class="{ active: props.page === item.value }"
          :pageNumber="item.value"
          @clickNumber="changePage"
        />
      </div>
    </div>

    <GrPaginationNext
      class="pagination-list-item"
      :disabled="nextIsDisabled"
      @click="nextPage"
    />
  </ol>
</template>

<style lang="scss" scoped>
.pagination-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1-5);
  align-items: center;
  font-size: var(--font-sm);
  line-height: --var(--spacing-5);
  overflow-wrap: break-word;
  list-style-type: none;

  @include size(md) {
    flex-wrap: nowrap;
  }
}

.pagination-list-item {
  background-color: transparent;
  border: 0;
  color: var(--gray-200);
  font-size: var(--font-sm);
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  &:hover {
    background-color: var(--gray-50);
  }

  &.active {
    color: var(--gray-white);
    background-color: var(--primary-800) !important;
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
}
</style>
