<script setup>
import { onMounted, provide, ref, watch } from "vue";

const props = defineProps({
  default: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:default"]);

const selectedTab = ref(props.default);
const tabsLoaded = ref([]);

const setSelectedTab = (newValue) => {
  selectedTab.value = newValue;

  if (!tabsLoaded.value.includes(newValue)) {
    tabsLoaded.value.push(newValue);
  }

  emit("update:default", newValue);
};

provide("tabsLoaded", tabsLoaded);
provide("selectedTab", selectedTab);
provide("setSelectedTab", setSelectedTab);

watch(
  () => props.default,
  (newVal) => {
    selectedTab.value = newVal;
  }
);
onMounted(() => {
  tabsLoaded.value.push(props.default);
});
</script>

<template>
  <div class="container-tabs">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.container-tabs {
  display: grid;
  gap: var(--spacing-5);
  overflow: hidden;
}
</style>
