<script setup>
import { ExportDescription, ExportTitle } from "../base-components";

import tagMoneyActive from "@/assets/img/icons/opportunities/tag-money-active.svg";
import tagMoneyDisabled from "@/assets/img/icons/opportunities/tag-money-disabled.svg";
import { useI18n } from "@/utils/useI18n";

const i18n = useI18n();

const emit = defineEmits(["nextFile"]);

const exportOptions = [
  {
    format: ".CSV",
    value: "csv",
    description: i18n.t("opportunities.export.fileExport.descriptionCsv"),
    icon: tagMoneyActive,
    disabled: false,
    id: "modalprofit_tipoarquivo_exportacao_csv",
  },
  {
    format: ".XLSX",
    value: "xlsx",
    description: i18n.t("opportunities.export.fileExport.descriptionXlsx"),
    icon: tagMoneyDisabled,
    disabled: true,
    id: "modalprofit_tipoarquivo_exportacao_xlsx",
  },
];

function selectFile(file) {
  if (file === "xlsx") return;
  emit("nextFile");
}
</script>

<template>
  <div class="options">
    <ExportTitle> {{ $t("opportunities.export.fileExport.title") }} </ExportTitle>

    <ExportDescription class="description">
      {{ $t("opportunities.export.fileExport.description") }}
    </ExportDescription>

    <div
      v-for="(option, index) in exportOptions"
      :key="index"
      :id="option.id"
      :class="['card-file', { disabled: option.disabled }]"
      @click="selectFile(option.value)"
    >
      <div v-if="option.disabled" class="tag-disabled">
        {{ $t("opportunities.export.fileExport.tabDisabled") }}
      </div>

      <section>
        <img :src="option.icon" alt="File" />
        <span>{{ option.format }}</span>
      </section>

      <p>{{ option.description }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.options {
  display: grid;
  gap: 20px;
  padding: 0;

  .description {
    padding: 0 90px;
  }

  .card-file {
    width: 100%;
    height: 145px;

    display: grid;
    gap: 5px;
    padding: 20px;

    background-color: #ebfef6;
    border-radius: 10px;
    border: none;
    cursor: pointer;

    transition: all 0.3s;

    &:hover {
      transform: scale(0.995);
    }

    section {
      display: flex;
      align-items: center;
      gap: 15px;

      span {
        font-size: 16px;
        font-weight: 700;
        color: var(--accent-600);
      }
    }

    p {
      font-weight: 400;
      color: var(--text);
      line-height: 25px;
      max-width: 75%;
    }
  }

  .disabled {
    background-color: var(--gray-10);
    position: relative;
    opacity: 0.9;

    cursor: not-allowed;
    section {
      opacity: 0.5;
      span {
        color: var(--text);
      }
    }

    p {
      color: var(--gray-200);
    }

    .tag-disabled {
      position: absolute;
      right: -15px;
      top: -15px;

      width: 83px;
      height: 32px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 5px;
      background-color: #3886f9;

      color: var(--gray-white);
      font-size: 13px;
      font-weight: 600;
    }
  }
}
</style>
