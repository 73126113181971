<script setup>
import {
  GrTable,
  GrTableHeader,
  GrTableHead,
  GrTableBody,
  GrTableRow,
  GrTableCell,
  GrTableEmpty
} from "@/components/GrComponents/table";
import { GrTag } from "@/components/GrComponents";

import { onMounted, computed, ref } from "vue";
import { useStore } from "@/utils/useStore";
import PaginateOpportunities from "./PaginateOpportunities.vue";
import { money } from "@/utils/money";
import moment from "moment";

import boletoIcon from "@/assets/img/icons/opportunities/boleto.svg";
import cartIcon from "@/assets/img/icons/opportunities/cart.svg";
import cardIcon from "@/assets/img/icons/opportunities/card.svg";
import qrCodeIcon from "@/assets/img/icons/opportunities/qr_code.svg";
import { Sheet, SheetContent, SheetTitle, SheetSubTitle } from "@/components/GrComponents/sheet";
import { useI18n } from "@/utils/useI18n";
import {
  OriginOpportunity,
  TimelineOpportunity,
  StatusOpportunity,
  AboutSaleOpportunity,
} from "./details";

const i18n = useI18n();
const store = useStore();

const isOpenSheet = ref(false);
const loadingUnique = ref(false);
const loadingFindOpportunity = computed({
  get() {
    return store.getters["opportunities/getLoadindOpportunities"];
  },
  set() {
    store.dispatch("opportunities/updateLoadingOpportunities");
  },
});

const opportunities = computed(() => {
  return store.getters["opportunities/getOpportunities"];
});

const getOpportunitySelected = computed(
  () => store.getters["opportunities/getOpportunitySelected"]
);

function getStatusVariant(status) {
  const variants = {
    WON: "success",
    IN_PROGRESS: "info",
    LOST: "danger",
    NOT_APPLICABLE: "warning-light",
    PENDING: "danger",
  };
  return variants[status] || "warning-light";
}

function getStatusText(status) {
  const texts = {
    WON: i18n.t("opportunities.status.WON"),
    IN_PROGRESS: i18n.t("opportunities.status.IN_PROGRESS"),
    LOST: i18n.t("opportunities.status.LOST"),
    NOT_APPLICABLE: i18n.t("opportunities.status.NOT_APPLICABLE"),
    PENDING: i18n.t("opportunities.status.PENDING"),
  };
  return texts[status] || status;
}

function getOriginText(origin) {
  const texts = {
    PIX_NOT_PAID: i18n.t("opportunities.origin.NOT_PAID"),
    BOLETO_NOT_PAID: i18n.t("opportunities.origin.NOT_PAID"),
    CARD_DECLINED: i18n.t("opportunities.origin.CARD_DECLINED"),
    INSUFICIENT_BALANCE: i18n.t("opportunities.origin.INSUFICIENT_BALANCE"),
    ABANDONED_CART: i18n.t("opportunities.origin.CHECKOUT_ABANDONED"),
  };
  return texts[origin] || origin;
}

function getSaleMethod(reason) {
  const variants = {
    PIX_NOT_PAID: qrCodeIcon,
    BOLETO_NOT_PAID: boletoIcon,
    CARD_DECLINED: cardIcon,
    INSUFICIENT_BALANCE: cardIcon,
    ABANDONED_CART: cartIcon,
  };

  return variants[reason] || cartIcon;
}

async function getOpportunities() {
  await store.dispatch("opportunities/getOpportunities");
}

async function openOpportunity(id) {
  isOpenSheet.value = !isOpenSheet.value;

  if (!id) return;
  loadingUnique.value = true;

  try {
    await store.dispatch("opportunities/findUniqueOpportunity", id);
  } catch (error) {
    console.error(error);
  } finally {
    loadingUnique.value = false;
  }
}

onMounted(async () => {
  await getOpportunities();
});
</script>

<template>
  <div class="list-min">
    <GrTable :loading="loadingFindOpportunity">
      <GrTableHeader>
        <GrTableHead> {{ $t("opportunities.table.createdDate") }} </GrTableHead>
        <GrTableHead> {{ $t("opportunities.table.clientName") }} </GrTableHead>
        <GrTableHead> {{ $t("opportunities.table.product") }} </GrTableHead>
        <GrTableHead> {{ $t("opportunities.table.amount") }} </GrTableHead>
        <GrTableHead> {{ $t("opportunities.table.updatedDate") }} </GrTableHead>
        <GrTableHead> {{ $t("opportunities.table.origin") }} </GrTableHead>
        <GrTableHead> {{ $t("opportunities.table.status") }} </GrTableHead>
      </GrTableHeader>

      <GrTableBody>
        <GrTableRow v-for="(item, index) in opportunities" :key="index" @click="openOpportunity(item?.id)">
          <GrTableCell>
            {{ moment(item.created_at).format($t("locale_date.formats.shortYear")) }}
          </GrTableCell>
          <GrTableCell>{{ item.lead.name }}</GrTableCell>
          <GrTableCell>{{ item.name }}</GrTableCell>
          <GrTableCell> {{ money(item.amount, "R$") }}</GrTableCell>
          <GrTableCell>{{ moment(item.updated_at).locale($t("locale")).fromNow() }}</GrTableCell>

          <GrTableCell>
            <div class="origin">
              <img :src="getSaleMethod(item.reason)" />
              <span>{{ getOriginText(item.reason) }}</span>
            </div>
          </GrTableCell>

          <GrTableCell class="cell-status">
            <GrTag class="tag" :variant="getStatusVariant(item.status)">
              {{ getStatusText(item.status) }}
            </GrTag>

            <img
              src="@/assets/img/icons/opportunities/arrow.svg"
              alt="arrow"
            />
          </GrTableCell>
        </GrTableRow>
      </GrTableBody>

      <GrTableEmpty v-if="!opportunities.length"> {{ $t("opportunities.empty") }} </GrTableEmpty>
    </GrTable>

    <PaginateOpportunities />

    <Sheet :modelValue.sync="isOpenSheet">
      <SheetContent :loading="loadingUnique">
        <SheetTitle> #{{ getOpportunitySelected.id }} </SheetTitle>

        <StatusOpportunity />

        <SheetSubTitle> {{ $t("opportunities.details.originOpportunity") }} </SheetSubTitle>
        <OriginOpportunity />

        <SheetSubTitle v-if="getOpportunitySelected?.timelines?.length > 0">
          {{ $t("opportunities.details.timeline.titleTimeline") }}
        </SheetSubTitle>
        <TimelineOpportunity v-if="getOpportunitySelected?.timelines?.length > 0" />

        <SheetSubTitle> {{ $t("opportunities.details.aboutSale") }} </SheetSubTitle>
        <AboutSaleOpportunity />
      </SheetContent>
    </Sheet>
  </div>
</template>

<style lang="scss" scoped>
.cell-status {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-right: var(--spacing-1-5);

  img {
    padding: 5px;
    cursor: pointer;
  }
}
.list-min {
  min-height: 32rem;
}
.origin {
  display: flex;
  align-items: baseline;
  gap: 10px;
}
</style>
