<script setup>
import { ref, computed, inject, provide } from 'vue';
import { GrAvatar } from "@/components/GrComponents"
import Question from './Question.vue';
const props = defineProps({
  information: {
    type: Object,
    required: true,
  },
  index: {
    type: [Number, String],
    required: true,
  },
  lestChild:{
    type: Boolean
  }
});
const indexAnswer = ref(false)

const show = computed(() => questionShow.value === props.index);

const selectedAnswer = computed({
  get(){
    return indexAnswer.value
  },
  set(index){    
    indexAnswer.value = index === indexAnswer.value ? false : index;
  }
})

const contQuestion = computed(() => props.information.product_questions.length )
const questionList = computed(()=>{
  return props.information.product_questions
}) 

function setShow() {
  questionShow.value = props.index
}

const questionShow = inject('questionShow');
provide('selectedAnswer', selectedAnswer)

</script>
<template>
  <div class="content d-grid " :class="{'bar-footer':!lestChild }">
    <div class="flex-between">
      <div class="name-icon">        
        <GrAvatar :src="information.main_image.path" :placeholder="information.name"></GrAvatar>
        <div class="d-grid">
          <span class="title">
            {{ information.name }}
          </span>
          <span class="question-caunt">{{ contQuestion }} {{ $t('opportunities.questions_answer.caunt') }}</span>
        </div>
      </div>
      <img class="pointer animation-show" :class="{'rotate' : show}"  @click="setShow" src="@/assets/img/icons/opportunities/arrowUp.svg" />
    </div>
      <div class="not-active" :class="{ 'active' : show }" >
        <div v-for="(item, index) in questionList" :key="index">
          <Question :question="item" :index="index"/>
        </div>
      </div>
  </div>
</template>
<style lang="scss" scoped>
.bar-footer {
  &::after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    padding-bottom: var(--spacing-8);
    border-bottom: solid 1px var(--gray-50);
  }
}
.flex-between{
    display: flex;
    justify-content: space-between;
  }
  
.animation-show{
  transition: all ease-in 0.3s;
}

.not-active{
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-in-out;
}
.active{
  max-height: var(--h-full);
}
.rotate{
  transform: rotate(180deg);
}
.content {
  padding-top: var(--spacing-8);
  padding-bottom: var(--spacing-8);

  .name-icon {
    display: flex;
    gap: var(--spacing-8)
  }

  .d-grid {
    display: grid;
  }

  .pointer {
    cursor: pointer;
  }

  .title {
    color: var(--primary-800);
    font-weight: var(--weight-semibold);
    font-size: var(--font-md);
  }

  .question-caunt {
    color: var(--gray-200);
    font-size: var(--font-xs);
  }

  .response {
    color: var(--gray-200);
    font-size: var(--font-md);
    font-weight: var(--weight-normal);
    line-height: var(--h-7);
  }
}
</style>