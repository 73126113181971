<script setup>
import { computed, onMounted, provide, ref } from 'vue';
import { GrButton } from "@/components/GrComponents"
import { useStore } from "@/utils/useStore";
import QuestionGrup from './QuestionGrup.vue';
import ModalEditQuestion from './ModalEditQuestion.vue';
import ModalDeletQuetion from './ModalDeletQuetion.vue';

ModalDeletQuetion
const store = useStore();
//variables
const loadingList = ref(false)
const selected = ref(false);
const modalOpen = ref(false)
const modalOpenDelet = ref(false)
const questionSelected = ref([])
//computed
const listProductisQuestions = computed({
  get() {
    return store.getters['questions/getQuestionsList']
  },
})

const getSetModalVelue = computed({
  get() {
    return modalOpen.value
  },
  set(value) {
    questionSelected.value = value
    modalOpen.value = !modalOpen.value
  }
})

const getSetModalDelet = computed({
  get() {
    return modalOpenDelet.value
  },
  set(value) {
    questionSelected.value = value
    modalOpenDelet.value = !modalOpenDelet.value
  }
})

const questionShow = computed({
  get() {
    return selected.value
  },
  set(value) {
    selected.value = value === selected.value ? false : value;
  }
})
const getPagination = computed(()=> store.getters['questions/getPagination'])

async function nextPage(){
  await store.dispatch('questions/setNextPage')  
  store.dispatch('questions/getQuestionList',getPagination.value)
}
async function editInList(questionEdited){
  // Não sei pq mais precisou desse true e false para atualizar o v-for 
  loadingList.value = true
  await store.dispatch('questions/updateUniqueQuestion',questionEdited)
  loadingList.value = false

}
provide('questionShow', questionShow)
provide('questionSelected', questionSelected)
provide('getSetModalVelue', getSetModalVelue)
provide('getSetModalDelet', getSetModalDelet)

onMounted(() => {
  if(!listProductisQuestions.value.length ){
    store.dispatch('questions/getQuestionList')
  }
})
</script>

<template>
  <div >
    <div v-if="!loadingList" class="space-top question-item">
      <div  v-for="(item, index) in listProductisQuestions" :key="index">
        <QuestionGrup :lestChild="listProductisQuestions.length - 1 == index" :information="item" :index="index" />
      </div>
      <div class="center-content" >
        <GrButton variant="gray-light" @click="nextPage()">{{ $t('opportunities.questions_answer.more_loading') }}</GrButton>
      </div>
    </div>
    <ModalEditQuestion @updated="editInList($event)"/>
    <ModalDeletQuetion />
  </div>
</template>

<style lang="scss" scoped>
.space-top {
  padding-top: var(--spacing-14) !important;
}
.center-content{
  display: flex;
  justify-content: center;
}
</style>