<script setup>
import { computed, ref } from "vue";
import { useStore } from "@/utils/useStore";
import { GrTag } from "@/components/GrComponents";
import { useI18n } from "@/utils/useI18n";
const i18n = useI18n()
const store = useStore();

const filtersApplied = computed(() => {
  const filters = store.getters["opportunities/getFilterOpportunities"];

  const filteredEntries = Object.entries(filters).filter(([key, value]) => {
    return value !== null && key !== "startDate" && key !== "endDate";
  });
  const filteredObject = Object.fromEntries(filteredEntries);
  return filteredObject
});

const labelKeys = ref({
  id: 'ID:',
  leadName: i18n.t('opportunities.filter.searchName.label'),
  leadEmail: i18n.t('opportunities.filter.searchEmail.label'),
  leadDocument: i18n.t('opportunities.filter.searchDoc.label'),
  leadCellphone: i18n.t('opportunities.filter.searchPhone.label'),
})

const mutationsKeys = ref({
  id: 'setFilterId',
  leadName: 'setFilterLeadName',
  leadEmail: 'setFilterLeadEmail',
  leadDocument: 'setFilterLeadDocument',
  leadCellphone: 'setFilterLeadCellphone',
})


async function clearFilter(key, value = '') {

  await store.dispatch('opportunities/setNewValueInFilter', { mutationName: mutationsKeys.value[key], newValue: value })
  await store.dispatch('opportunities/setPageOne')
  store.dispatch('opportunities/getOpportunities')
}

function formarLabel(key) { 
  return labelKeys.value[key]
}
</script>

<template>
  <div data-anima="top">
    <div class="container-filter">
      <div v-for="(value, key, index) in filtersApplied " :key="index">
        <div v-if="value" class="filter-item">
          <template>
            <GrTag custom-background="#F0F2F5">
              <span class="filter-label">{{ formarLabel(key) }}</span>
              <span class="filter-value">{{ value }}</span>
            </GrTag>
            <svg @click="clearFilter(key)" width="17" height="17" viewBox="0 0 17 17" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect width="17" height="17" rx="8.5" fill="#003B36" />
              <path d="M11.5152 5.48438L5.48486 11.5147M5.48486 5.48438L11.5152 11.5147" stroke="white"
                stroke-width="1.50759" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container-filter {
  display: flex;
  width: var(--w-full);
  gap: var(--spacing-2);
  flex-wrap: wrap;
  padding: var(--spacing-3) 0;

  .array-container {
    display: flex;
    gap: var(--spacing-2);
    flex-wrap: wrap;
  }

  .filter-item {
    position: relative;
    width: fit-content;

    svg {
      position: absolute;
      top: -4px;
      right: -4px;
      cursor: pointer;
    }

    .filter-label {
      color: var(--text);
      font-size: var(--font-xs);
      font-weight: var(--weight-medium);
    }

    .filter-value {
      color: var(--text);
      font-size: var(--font-xs);
      font-weight: var(--weight-bold);
      margin-left: var(--spacing-1);
    }
  }
}
</style>
